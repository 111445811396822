import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { bannerApis } from '../../../apis/banner.apis';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import {Link} from "react-router-dom";

interface BannerProps {}

export const Banner: FC<BannerProps> = () => {
  const query = useQuery({
    queryKey: ['banner'],
    queryFn: bannerApis.getAll,
  });
  const banners = query.data?.rows ?? [];
  return (
    <CarouselWrapper>
      <CustomCarousel
        scrollbar={{
          hide: false,
        }}
        autoplay={{ delay: 5000 }}
        modules={[Autoplay]}
      >
        {banners.length > 0
          ? banners.map((v) => {
              return (
                <SwiperSlide key={v._id} style={{ height: '100%' }}>
                  <Link to={v.url}>
                    <BannerItem data-src={v.image.url}></BannerItem>
                  </Link>
                </SwiperSlide>
              );
            })
          : null}
        <BottomGradient />
      </CustomCarousel>
    </CarouselWrapper>
  );
};

const BannerItem = styled.div<{ 'data-src': string }>`
  height: 100%;
  background-image: url(${(v) => `'${v['data-src']}'`});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const CustomCarousel = styled(Swiper)`
  height: 100%;
`;
const CarouselWrapper = styled.div`
  height: 100%;
`;

const BottomGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 100%);
  height: 62px;
  z-index: 1;
`;
