import * as yup from 'yup';
import {usernameValidate} from "./username.validate";
import {passwordValidate} from "./password.validate";
import {emailValidate} from "./email.validate";
import {nameValidate} from "./name.validate";
import {passwordConfirmValidate} from "./passwordConfirm.validate";
import {businessTypeValidate} from "./businessType.validate";

export const registerYup = yup.object().shape({
  username: usernameValidate,
  name: nameValidate,
  email: emailValidate,
  password:passwordValidate,
  passwordConfirm: passwordConfirmValidate,
  business_type: businessTypeValidate,
});
