import { TNewsListItem } from '../types/new-list-item.type';
import { httpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function getList(page: number) {
  const url = `/news?page=${page}`;
  try {
    const response = await httpClient.get(url).then((res) => {
      return res.data as {
        total_count: number;
        rows: {
          _id: string;
          title: string;
          url: string;
          created_at: string;
          image?: string;
          date: string;
        }[];
        page: number;
        page_size: number;
      };
    });
    return {
      ...response,
      rows: response.rows.map(
        (v) =>
          ({
            ...v,
            created_at: new Date(v.created_at),
          } as TNewsListItem)
      ),
    };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function getLink(id: string) {
  try {
    const url = `/news/${id}/link`;
    const response = await httpClient
      .get(url)
      .then((res) => res.data as { link: string });
    return response;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export const newsApis = { getList, getLink };
